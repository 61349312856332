import React, { useState, useEffect } from 'react';

interface CountUpProps {
  end: number;
  start?: number;
  duration?: number;
  delay?: number;
  prefix?: string;
  suffix?: string;
  className?: string;
  shouldStart?: boolean;
}

export const CountUp: React.FC<CountUpProps> = ({
  end,
  start = 0,
  duration = 2000,
  delay = 0,
  prefix = '',
  suffix = '',
  className = '',
  shouldStart = false,
}) => {
  const [count, setCount] = useState(start);

  useEffect(() => {
    // Only start animation if shouldStart is true
    if (!shouldStart) {
      return;
    }
    
    let startTime: number;
    let animationFrame: number;
    
    const startAnimation = (timestamp: number) => {
      if (!startTime) startTime = timestamp;
      const progress = timestamp - startTime;
      
      if (progress < duration) {
        const nextCount = Math.floor(start + (end - start) * (progress / duration));
        setCount(nextCount);
        animationFrame = requestAnimationFrame(startAnimation);
      } else {
        setCount(end);
      }
    };
    
    const delayTimeout = setTimeout(() => {
      animationFrame = requestAnimationFrame(startAnimation);
    }, delay);
    
    return () => {
      clearTimeout(delayTimeout);
      if (animationFrame) {
        cancelAnimationFrame(animationFrame);
      }
    };
  }, [start, end, duration, delay, shouldStart]);
  
  return (
    <span className={className}>
      {prefix}{count.toLocaleString()}{suffix}
    </span>
  );
}; 