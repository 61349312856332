import React, { useEffect, useState, useRef } from 'react';
import { ExternalLink, Linkedin, Instagram, X, Music, ChevronDown, Mail } from 'lucide-react';
import { CountUp } from './components/CountUp';

// Define keyframe animations for modal
const fadeInKeyframes = `
@keyframes fadeIn {
  from { opacity: 0; transform: translateY(10px); }
  to { opacity: 1; transform: translateY(0); }
}
`;

const MainPage: React.FC = () => {
  const [scrollY, setScrollY] = useState(0);
  // Refs to track which sections have already appeared
  const appearedSections = useRef<Record<string, boolean>>({
    projects: false,
    about: false
  });
  
  // Inject animation keyframes into document head
  useEffect(() => {
    // Add the keyframes to the document head
    const styleElement = document.createElement('style');
    styleElement.innerHTML = fadeInKeyframes;
    document.head.appendChild(styleElement);
    
    return () => {
      // Clean up on unmount
      document.head.removeChild(styleElement);
    };
  }, []);
  
  useEffect(() => {
    const handleScroll = () => {
      setScrollY(window.scrollY);
      
      // Check each section only if it hasn't appeared yet
      Object.keys(appearedSections.current).forEach((sectionId) => {
        if (!appearedSections.current[sectionId]) {
          const element = document.getElementById(sectionId);
          if (element) {
            const rect = element.getBoundingClientRect();
            const windowHeight = window.innerHeight || document.documentElement.clientHeight;
            
            if (rect.top <= windowHeight * 0.85) {
              appearedSections.current[sectionId] = true;
              // Force re-render to update UI
              setScrollY(prev => prev + 0.1); // Add a small value to ensure state change
            }
          }
        }
      });
    };

    window.addEventListener('scroll', handleScroll);
    // Trigger check on initial load
    handleScroll();
    
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  // Get animation class based on whether section has appeared
  const fadeInClass = (elementId: string) => {
    return appearedSections.current[elementId] ? 'opacity-100 translate-y-0' : 'opacity-0 translate-y-10';
  };

  // Function to handle email click
  const handleEmailClick = (e: React.MouseEvent) => {
    e.preventDefault();
    const emailAddress = 'marcelo.barrera@stern.nyu.edu';
    const subject = 'Hello from your portfolio';
    
    // Try to open the email client
    window.open(`mailto:${emailAddress}?subject=${encodeURIComponent(subject)}`, '_self');
  };

  return (
    <div className="bg-white min-h-screen font-sans text-gray-800">
      <div className="max-w-5xl mx-auto p-6">
        {/* Hero Section */}
        <div className="flex flex-col items-center justify-center text-center min-h-[85vh] py-12 relative">
          <div className={`transition-all duration-1000 max-w-2xl mx-auto ${scrollY > 50 ? 'opacity-70 -translate-y-2' : 'opacity-100'}`}>
            <img 
              src="/1677561732317.jpeg" 
              alt="Marcelo Barrera" 
              className="w-44 h-44 md:w-48 md:h-48 rounded-full mb-8 shadow-lg object-cover border-4 border-white mx-auto" 
            />
            <h1 className="text-5xl md:text-6xl font-bold mb-4 text-blue-600 tracking-tight">
              hi, i'm marcelo
            </h1>
            <p className="text-xl md:text-2xl text-gray-600 mb-8">
              i'm a growth hacker
            </p>
            
            <div className="flex justify-center space-x-6 mt-6">
              <a href="https://www.instagram.com/marcelobarreracv" target="_blank" rel="noopener noreferrer" 
                className="bg-gradient-to-br from-purple-500 to-pink-500 p-3 rounded-full shadow-md hover:shadow-lg transition-all duration-300 transform hover:-translate-y-1 group">
                <Instagram size={26} className="text-white" />
              </a>
              <a href="https://twitter.com/MooseTrivia" target="_blank" rel="noopener noreferrer" 
                className="bg-black p-3 rounded-full shadow-md hover:shadow-lg transition-all duration-300 transform hover:-translate-y-1 group">
                <X size={26} className="text-white" />
              </a>
              <a href="https://www.linkedin.com/in/marcelo-barrera/" target="_blank" rel="noopener noreferrer" 
                className="bg-blue-700 p-3 rounded-full shadow-md hover:shadow-lg transition-all duration-300 transform hover:-translate-y-1 group">
                <Linkedin size={26} className="text-white" />
              </a>
              <a href="https://open.spotify.com/user/marcelobarrera03" target="_blank" rel="noopener noreferrer" 
                className="bg-[#1DB954] p-3 rounded-full shadow-md hover:shadow-lg transition-all duration-300 transform hover:-translate-y-1 group">
                <Music size={26} className="text-white" />
              </a>
              <button
                onClick={handleEmailClick}
                className="bg-gradient-to-br from-blue-400 to-blue-500 p-3 rounded-full shadow-md hover:shadow-lg transition-all duration-300 transform hover:-translate-y-1 group">
                <Mail size={26} className="text-white" />
              </button>
            </div>
          </div>
          
          {/* Scroll indicator */}
          <div className="absolute bottom-6 left-0 right-0 flex justify-center">
            <div className="animate-bounce p-3 bg-blue-100 rounded-full shadow-md border border-blue-200 cursor-pointer">
              <ChevronDown className="text-blue-600" size={22} />
            </div>
          </div>
        </div>

        {/* Project Cards */}
        <div id="projects" className={`mb-16 transition-all duration-1000 delay-200 ${fadeInClass('projects')}`}>
          <h2 className="text-3xl font-bold mb-8 text-blue-600 text-center">
            Projects I've Grown
          </h2>
          
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-6">
            {/* Moose Trivia */}
            <a 
              href="https://twitter.com/TriviaMoose" 
              target="_blank"
              rel="noopener noreferrer"
              className="bg-white rounded-2xl shadow-lg p-6 hover:shadow-xl transition-all duration-500 transform hover:-translate-y-1 flex flex-col h-full border border-gray-100"
            >
              <div className="flex items-center justify-center mb-5 h-24">
                <img 
                  src="/TransM.png" 
                  alt="Moose Trivia" 
                  className="h-20 w-auto object-contain" 
                />
              </div>
              <h3 className="text-xl font-bold mb-4 text-center text-blue-600">
                Moose Trivia
              </h3>
              <div className="mt-auto space-y-3">
                <div className="flex flex-col items-center">
                  <p className="text-sm text-gray-500 mb-1">ARR:</p>
                  <CountUp 
                    end={300000} 
                    prefix="$" 
                    className="text-2xl font-bold text-blue-600"
                    shouldStart={appearedSections.current.projects}
                  />
                </div>
                <div className="flex flex-col items-center">
                  <p className="text-sm text-gray-500 mb-1">Impressions:</p>
                  <CountUp 
                    end={250} 
                    suffix="k+" 
                    className="text-2xl font-bold text-blue-600"
                    shouldStart={appearedSections.current.projects}
                  />
                </div>
              </div>
              <div className="flex justify-center mt-4">
                <ExternalLink size={16} className="text-gray-400" />
              </div>
            </a>
            
            {/* Limitless Shoes */}
            <a 
              href="#" 
              target="_blank"
              rel="noopener noreferrer"
              className="bg-white rounded-2xl shadow-lg p-6 hover:shadow-xl transition-all duration-500 transform hover:-translate-y-1 flex flex-col h-full border border-gray-100"
            >
              <div className="flex items-center justify-center mb-5 h-24">
                <div className="text-4xl font-bold text-blue-600">👟</div>
              </div>
              <h3 className="text-xl font-bold mb-4 text-center text-blue-600">
                Limitless Shoes
              </h3>
              <div className="mt-auto space-y-3">
                <div className="flex flex-col items-center">
                  <p className="text-sm text-gray-500 mb-1">Revenue:</p>
                  <CountUp 
                    end={500000} 
                    prefix="$" 
                    className="text-2xl font-bold text-blue-600"
                    shouldStart={appearedSections.current.projects}
                  />
                </div>
                <div className="flex flex-col items-center">
                  <p className="text-sm text-gray-500 mb-1">Products:</p>
                  <CountUp 
                    end={2000} 
                    suffix="+" 
                    className="text-2xl font-bold text-blue-600"
                    shouldStart={appearedSections.current.projects}
                  />
                </div>
              </div>
              <div className="flex justify-center mt-4">
                <ExternalLink size={16} className="text-gray-400" />
              </div>
            </a>

            {/* Chef Santa */}
            <a 
              href="https://twitter.com/chefsanta2021" 
              target="_blank"
              rel="noopener noreferrer"
              className="bg-white rounded-2xl shadow-lg p-6 hover:shadow-xl transition-all duration-500 transform hover:-translate-y-1 flex flex-col h-full border border-gray-100"
            >
              <div className="flex items-center justify-center mb-5 h-24">
                <img 
                  src="/2uVV-ESN_400x400.jpg" 
                  alt="Chef Santa" 
                  className="h-20 w-auto object-contain rounded-full" 
                />
              </div>
              <h3 className="text-xl font-bold mb-4 text-center text-blue-600">
                Chef Santa
              </h3>
              <div className="mt-auto space-y-3">
                <div className="flex flex-col items-center">
                  <p className="text-sm text-gray-500 mb-1">Donated:</p>
                  <CountUp 
                    end={106000} 
                    prefix="$" 
                    className="text-2xl font-bold text-blue-600"
                    shouldStart={appearedSections.current.projects}
                  />
                </div>
                <div className="flex flex-col items-center">
                  <p className="text-sm text-gray-500 mb-1">Impressions:</p>
                  <CountUp 
                    end={400} 
                    suffix="k+" 
                    className="text-2xl font-bold text-blue-600"
                    shouldStart={appearedSections.current.projects}
                  />
                </div>
              </div>
              <div className="flex justify-center mt-4">
                <ExternalLink size={16} className="text-gray-400" />
              </div>
            </a>
            
            {/* Size Matters Chipotle */}
            <a 
              href="https://www.sizematterschipotle.com/" 
              target="_blank"
              rel="noopener noreferrer"
              className="bg-white rounded-2xl shadow-lg p-6 hover:shadow-xl transition-all duration-500 transform hover:-translate-y-1 flex flex-col h-full border border-gray-100"
            >
              <div className="flex items-center justify-center mb-5 h-24">
                <img 
                  src="/pngtree-delicious-burritos-clipart-illustration-png-image_6608563.png" 
                  alt="Chipotle" 
                  className="h-20 w-auto object-contain" 
                />
              </div>
              <h3 className="text-xl font-bold mb-4 text-center text-blue-600">
                Size Matters, Chipotle
              </h3>
              <div className="mt-auto space-y-3">
                <div className="flex flex-col items-center">
                  <p className="text-sm text-gray-500 mb-1">Reviews:</p>
                  <CountUp 
                    end={19000} 
                    className="text-2xl font-bold text-blue-600"
                    shouldStart={appearedSections.current.projects}
                  />
                </div>
                <div className="flex flex-col items-center">
                  <p className="text-sm text-gray-500 mb-1">Impressions:</p>
                  <CountUp 
                    end={100} 
                    suffix="k" 
                    className="text-2xl font-bold text-blue-600"
                    shouldStart={appearedSections.current.projects}
                  />
                </div>
              </div>
              <div className="flex justify-center mt-4">
                <ExternalLink size={16} className="text-gray-400" />
              </div>
            </a>
          </div>
        </div>
        
        {/* About Section */}
        <div id="about" className={`transition-all duration-1000 delay-200 ${fadeInClass('about')}`}>
          <h2 className="text-3xl font-bold mb-8 text-blue-600 text-center">About Me</h2>
          
          <div className="bg-white rounded-2xl shadow-lg p-8 border border-gray-100">
            <div className="flex flex-col md:flex-row items-stretch gap-8">
              {/* Left column - Education */}
              <div className="w-full md:w-2/5 bg-blue-50 p-6 rounded-xl flex flex-col items-center justify-center">
                <img 
                  src="/New-York-University-NYU-Stern-School-of-Business-Logo.png" 
                  alt="NYU Stern Logo" 
                  className="max-w-full h-auto max-h-32 object-contain mb-5"
                />
                <h3 className="text-lg font-bold text-blue-600 text-center mb-2">New York University</h3>
                <p className="text-gray-700 text-center font-medium">B.S. in Business, Technology, and Entrepreneurship</p>
              </div>
              
              {/* Right column - Story */}
              <div className="w-full md:w-3/5 p-6 flex flex-col justify-center">
                <p className="text-base text-gray-700 leading-relaxed">
                  I've been <span className="font-bold text-blue-600">addicted to building</span> since I port forwarded my home WiFi to play Minecraft with friends on my own server at age 7. That curiosity evolved into creating <span className="font-bold text-blue-600">Moose Trivia</span>, where I helped thousands of people win cash prizes from games like HQ Trivia. I also built <span className="font-bold text-blue-600">Size Matters, Chipotle</span>, where thousands of Chipotle lovers found locations near them that don't skimp out on portion sizes. Building things people genuinely love is my biggest rush.
                </p>
                <p className="text-base text-gray-700 leading-relaxed mt-3">
                  Currently, I'm on the hunt for <span className="font-bold text-blue-600">opportunities</span> that need a blend of creative thinking and data-driven execution.
                </p>
              </div>
            </div>
          </div>
        </div>
        
        {/* Footer */}
        <footer className="text-center text-gray-500 text-sm mt-12 pt-6 border-t border-gray-200">
          <p>© {new Date().getFullYear()} Marcelo Barrera</p>
        </footer>
      </div>
    </div>
  );
};

export default MainPage;