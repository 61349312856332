import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import MainPage from './MainPage';
import { DarkModeProvider } from './DarkModeContext';
import { Analytics } from '@vercel/analytics/react';

const App: React.FC = () => {
  return (
    <DarkModeProvider>
      <Router>
        <Routes>
          <Route path="/" element={<MainPage />} />
          {/* We're removing the other routes for a simplified single-page design */}
        </Routes>
      </Router>
      <Analytics />
    </DarkModeProvider>
  );
};

export default App;